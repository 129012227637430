import React, { useEffect, useContext } from "react";
import { exchangeCode, getWhoAmI } from "../../api";
import Loading from "../../components/loading";
import { logout } from "../../common";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { SecurePatternLayout } from "../../templates/SecurePatternLayout";
import { useSuccessLogin } from "../../hooks/useSuccessLogin";
import { ShowCompanyModalContext } from "../../hooks/useShowCompanyModal";
import { UserContext } from "../../hooks/useWhoAmI";

function LoginPage() {
  const search = useQuery();
  let code = search.get("code");
  let state = search.get("state");

  return (
    <SecurePatternLayout title="Login">
      <ExchangeComponent code={code} state={state} />
    </SecurePatternLayout>
  );
}

const ExchangeComponent = ({ code, state }) => {
  const history = useHistory();
  const { setShowCompanyModal } = useContext(ShowCompanyModalContext);
  const { setCookiesAndLogin } = useSuccessLogin();
  const { login } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let invite_token = null;
        let redirect_to = null;
        if (state) {
          try {
            const decodedState = JSON.parse(atob(state));
            invite_token = decodedState?.invite_token;
            redirect_to = decodedState?.redirect_to;
          } catch (ex) {
            console.error(ex);
          }
        }

        const startLogin = await exchangeCode(code, invite_token);
        if (startLogin.access) {
          login(startLogin);
          const whoamI = await getWhoAmI();
          if (whoamI?.company.length === 0) {
            // User has no company registered, clear tokens and go to signup flow
            logout(
              "/signup",
              "Your account is not connected to a company. Please sign up first.",
              "No account"
            );
          } else {
            setCookiesAndLogin({
              me: whoamI,
              history,
              setShowCompanyModal,
              url: redirect_to,
            });
          }
        }
      } catch (ex) {
        console.error(ex);
        logout("/login", "An error occurred.");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div className="flex flex-col items-center bg-white w-[480px] px-12 pt-14 pb-16 box-shadow rounded-2xl z-10">
      <Loading />
      <h1 className="mt-8 text-float-purple-1">Almost there!</h1>
      <h3 className="text-center mt-4 text-float-grey-90 text-base">
        Logging you in ...
      </h3>
    </div>
  );
};

export default LoginPage;
